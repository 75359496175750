import * as React from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Icon, Button,List, Form, Upload, message, Select, Col, Row, Spin, Divider, notification } from 'antd';
import { Link } from 'react-router-dom';
import { API } from '../../api/api';
import { api_url } from '../../api/env';
import './project.css'



interface IHomeProps {
  user?: any;
  currentProject?: number;
  isSignedIn?: Boolean;
  setCurrentProject?: (dispatch: any) => void;
  projects?:any;
  setProjects?: (projects: any) => void;
  deleteProject?: (projectId: any) => void;
  setProjectId?: (projectId: any) => void;
  createProject?: (projectId: any) => void;

};

interface IHomeState {
};

class AccountHome extends React.Component<IHomeProps, IHomeState> {

  state = {
    contexts: [],
    nlp_data:{file:null, fileList:[], success:false},
    dsl_data:{file:null,ctx: null, fileList:[], success:false },
    loading: false,
  };
  componentDidMount() {
    API.listContexts(this.props.currentProject).then(r => {
      console.log('ll',r)
      let contexts = r.filter(c=> c.name !== 'Context-Free')
      this.setState({contexts});
    });
  }
  changeFileData(type,field,e){
    console.log('e',e)
    let stateElement = type +'_data'
    let newState = {... this.state[stateElement]}
    newState[field] = e
    this.setState({[stateElement]: newState},()=> console.log('stateeee',this.state))
  }
  onUpload(info, type){  
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    let newState = {... this.state[type + '_data']}
    newState.fileList = fileList
    this.setState({[type + '_data']: newState})
    if (info.file.status !== 'uploading') {
      newState.file = info.file
      this.setState({[type + '_data']: newState})
    }
  }
  submitUpload(type){
      this.setState({loading: true})
      const data = this.state[type+'_data']
      const formData = new FormData()
      formData.append('file', data.file.originFileObj);

      if(data.ctx){
        let ctx:any = this.state.contexts.find( (c:any) => c.name == data.ctx)
        let ctx_id = ctx && ctx.id
        formData.append('context_id', ctx_id);
      }
      let req;
      if(type == 'nlp'){
        req = API.uploadNlpFile(this.props.currentProject,formData)
      } else {
        req = API.uploadDslFile(this.props.currentProject,formData)
      }
      req.then(r=>{
        this.setState({loading: false})
        let newState = {... this.state[type + '_data']}
        if (r.status === 200) {
          newState.success = true
          this.setState({[type + '_data']: newState})
          notification.success({
            message: 'file uploaded successfully.',
            description: ''
          });
        } else {
          newState.success = false
          this.setState({[type + '_data']: newState})
          notification.error({
            message: 'File upload failed.',
            description: r.data,
            duration: 10
          });
        }
      })
      .catch(e=>{
        let newState = {... this.state[type + '_data']}
        newState.success = false
        this.setState({[type + '_data']: newState, loading: false})
        notification.error({
          message: 'File upload failed.',
          description: e.response.data.error,
          duration: 10
        });
      })
  }
  releaseProject(){
    API.release(this.props.currentProject).then(r => {
      notification.success({
        message:'Project is released successfully.',
        description:''
      })

    }).catch((e) => {
      console.log('Error releaseProject', e);
      notification.error({
        message:"Couldn't release the project",
        description:''
      })
    })
  }
  render() {
    let projectName = this.props.projects.find(p=> p.id === this.props.currentProject)
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
    return (
      <div className='account-home'>
        <Button style={{width: 'auto', border: 'none', color:'#fff', float:'right', marginRight: 50, height:40}}  onClick={() => this.releaseProject()} htmlType='submit' className='bot-btn main'>
          Release project
        </Button>
        <h1>{projectName.name} </h1>
        {this.state.loading && <Spin />}
        <Row className='uploads'>
          <Col md={12} >
            <h2 className='title'>Upload .DSL File</h2>
            <div className='box dsl'>
              <img width={60} src='/assets/images/file.svg'/>
              <Upload className='upload' customRequest={dummyRequest} onChange={(file)=>this.onUpload(file, 'dsl')} name='file' fileList={this.state.dsl_data.fileList} >
                <Button className='upload-btn'>
                  <Icon type="upload" /> Select file
                </Button>
                {this.state.dsl_data.fileList.length>0 && this.state.dsl_data.success && <Icon className='done' type="check-circle" />}
              </Upload>
              <Divider/>
              <div>
                  <span style={{fontSize: 28}}>Context:</span> &nbsp; &nbsp;<Select style={{width: 200}} size='large' onChange={(e)=>this.changeFileData('dsl','ctx',e)}>
                  {this.state.contexts.map((c:any)=>{
                    return <Select.Option key={c.name} value={c.name}>{c.name}</Select.Option>
                  })}
                </Select>
              </div>
              
              <Button className='bot-btn main' onClick={()=>this.submitUpload('dsl')}>Upload File</Button>
            </div>
            
          </Col>
         <Col md={12} >
              <h2 className='title'>Upload .NLP File</h2>
              <div className='box nlp'>
                <img width={60} src='/assets/images/file.svg'/>
                <Upload className='upload' customRequest={dummyRequest} onChange={(file)=>this.onUpload(file, 'nlp')} name='file'  fileList={this.state.nlp_data.fileList} >
                  <Button className='upload-btn'>
                    <Icon type="upload" /> Select file
                  </Button>
                  {this.state.nlp_data.fileList.length>0 &&  this.state.nlp_data.success &&<Icon className='done' type="check-circle" />}
                </Upload>
                <Button className='bot-btn main' onClick={()=>this.submitUpload('nlp')}>Upload File</Button>
              </div>
              
          </Col>
        </Row>
      </div>
    );
  }
}


function mapStateToProps(state: any) {
  return {
    user: state.reduxTokenAuth.currentUser,
    projects: state.projects,
    currentProject: state.bot.currentProject,
    projectId: state.projectId,
  };
}

const mapDispatchToProps = {

};

export default connect<any,any>(
  mapStateToProps,
  mapDispatchToProps
)(AccountHome);
